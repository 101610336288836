
/* create a style for the root class */
:root {
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-grey: #D9D9D9;
  --clr-accent: #172332;
  --clr-accent-bright-blue: #143974;
  --clr-accent-green: #499860;
  
  
  /*font sizes */
  --fs-h1-desktop: 60px;
  --fs-h1-mobile: 35px;
  --fs-h2-desktop: 40px;
  --fs-h2-mobile: 30px;
  
  /*font weights */
  --fw-very-light: 200;
  --fw-light: 300;
  --fw-normal: 400;
  --fw-med: 500;
  --fw-semi: 600;
  --fw-bold: 700;
  --fw-heavy: 800;
  
}

html {
  font-family: -apple-system, BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu";
  color: var( --clr-black);
  -webkit-appearance: none;
  overflow-x: hidden;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
background: var(--clr-white);
  margin: 0;
  padding: 0;
  /* display: flex;
  flex-direction: column; */
display: block;
}


/* ------------------------------------------ RESPONSIVE Styling --------------------------------------------------- */

/* for people who don't like animations */
@media(prefers-reduced-motion) {
.hidden {
  transition: none;
}
}

/* iphone pro */
@media (min-width: 360px) {

}

/* styles for tablet */
@media (min-width: 768px) {
}

/* styles for desktop */
@media (min-width: 991px) {

}

/* styles for desktop */
@media (min-width: 1330px) {

}
